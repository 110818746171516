import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import logo from "../_img/xeiro_logo.png";
import { Button, Link, Typography } from "@mui/material";

function Appbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const buttonSx = {
    "&:hover": {
      color: "#adcadb",
      backgroundColor: "inherit",
    },
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1, mb: 8 }}>
      <AppBar
        position="static"
        sx={{
          bgcolor: "#6E808C",
        }}
      >
        <Toolbar>
          <a className="navbar-brand" href="/">
            <img
              className="d-inline-block align-text-top"
              src={logo}
              width="60"
              height="auto"
            />
          </a>
          <Box sx={{ flexGrow: 1, ml: 2, display: { xs: "none", md: "flex" } }}>
            <Button href="/" sx={buttonSx} variant="text" color="inherit">
              Artikel
            </Button>
            {/* <Button
              href="/Material"
              sx={buttonSx}
              variant="text"
              color="inherit"
            >
              Material
            </Button> */}
          </Box>
          <div>
            <IconButton
              id="user-menu-btn"
              aria-controls={open ? "user-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenu}
              color="inherit"
              size="large"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "user-menu-btn",
              }}
            >
              <MenuItem onClick={handleClose}>Einstellungen</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Appbar;
