import React from "react";
import Chip from "@mui/material/Chip";

function MaterialBadge(props) {
  function getColor(kategorie) {
    switch (kategorie) {
      case "Blache":
        return "#6F818C";
        break;
      case "Tafel":
        return "#F451A0";
        break;
      case "Aufkleber":
        return "#F48C51";
        break;
      case "Magnet":
        return "#A351F4";
        break;
      case "Plakat":
        return "#00af49";
        break;
    }
  }
  return (
    <React.Fragment>
      <Chip
        label={props.material}
        sx={{ bgcolor: getColor(props.kategorie), color: "#fff" }}
      />
    </React.Fragment>
  );
}

export default MaterialBadge;
