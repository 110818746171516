import React from "react";
import { ArtikelContextProvider } from "../context/artikelContext";
import { Box } from "@mui/material";
import ArtikelTable from "./Artikel/ArtikelTable";
import AddButton from "./Artikel/Einlagern/AddButton";

function Artikel() {
  return (
    <div>
      <ArtikelContextProvider>
        <Box sx={{ mb: 3 }}>
          <AddButton />
        </Box>
        <ArtikelTable />
      </ArtikelContextProvider>
    </div>
  );
}

export default Artikel;
