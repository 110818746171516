import React, { createContext, useState, useEffect, useContext } from "react";
import NotificationContext from "./notificationContext";

const MaterialContext = createContext({ materials: [] });

const MaterialContextProvider = ({ children }) => {
  const [materials, setMaterials] = useState([]);

  const notiContext = useContext(NotificationContext);

  const fetchMaterials = async () => {
    fetch("./API/Lager/getMaterials.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setMaterials(data);
      })
      .catch((error) => console.log("An error occured", error));
  };

  const createMaterial = async (data) => {
    fetch("./API/Lager/createMaterial.php", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          notiContext.error("Material konnte nicht erstellt werden");
          throw new Error("Material konnte nicht erstellt werden");
        }
        notiContext.success("Material erstellt!");
        return response.json();
      })
      .catch((error) => {
        console.error("Fehler beim Erstellen des Materials!", error);
      });
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  return (
    <MaterialContext.Provider
      value={{
        materials,
        setMaterials,
        createMaterial,
      }}
    >
      {children}
    </MaterialContext.Provider>
  );
};

export { MaterialContextProvider };
export default MaterialContext;
