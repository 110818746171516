import React, { useState, useEffect, useReducer, useContext } from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import ArtikelContext from "../../../context/artikelContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const platteReducer = (state, event) => {
  if (event.reset) {
    return {
      material: "",
      staerke: "",
      laenge: "",
      breite: "",
    };
  }
  return {
    ...state,
    [event.name]: event.value,
  };
};

function PlatteForm() {
  const [platteData, setPlatteData] = useReducer(platteReducer, {});
  const [selectedMaterial, setSelectedMaterial] = useState([]);
  const [staerken, setStaerken] = useState([]);
  const plattetext = "Platte";

  const { plattematerials, createArtikel } = useContext(ArtikelContext);

  const getStaerken = (material) => {
    plattematerials.map((data) => {
      if (data.material == material) {
        setStaerken(data.staerke.split(","));
      }
    });
  };

  const handleChange = (e) => {
    setPlatteData({
      name: e.target.name,
      value: e.target.value,
    });
    setSelectedMaterial(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      `material: ${platteData.material}, stärke: ${platteData.staerke}, länge: ${platteData.laenge}, breite: ${platteData.breite}`
    );
    createArtikel(platteData, plattetext);
    setPlatteData({ reset: true });
  };

  useEffect(() => {
    getStaerken(selectedMaterial);
  }, [selectedMaterial]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Material</InputLabel>
                <Select
                  name="material"
                  value={platteData.material || ""}
                  label="Material"
                  MenuProps={MenuProps}
                  fullWidth
                  onChange={handleChange}
                >
                  {plattematerials.map((material) => (
                    <MenuItem key={material.id} value={material.material}>
                      {material.material}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Stärke</InputLabel>
                <Select
                  name="staerke"
                  value={platteData.staerke || ""}
                  label="Stärke"
                  fullWidth
                  onChange={handleChange}
                >
                  {staerken.map((staerke) => (
                    <MenuItem key={staerke} value={staerke}>
                      {staerke}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Länge</InputLabel>
                <OutlinedInput
                  name="laenge"
                  type="number"
                  value={platteData.laenge || ""}
                  onChange={handleChange}
                  inputProps={{
                    inputProps: {
                      min: 0,
                      step: ".01",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">cm</InputAdornment>
                  }
                  label="Länge"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Breite</InputLabel>
                <OutlinedInput
                  name="breite"
                  type="number"
                  value={platteData.breite || ""}
                  onChange={handleChange}
                  inputProps={{
                    inputProps: {
                      min: 0,
                      step: ".01",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">cm</InputAdornment>
                  }
                  label="Breite"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button variant="contained" type="submit" fullWidth>
                Hinzufügen
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </React.Fragment>
  );
}

export default PlatteForm;
