import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Materialform from "./Materialform";

function AddButton() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      setOpen(false);
    } else if (reason == "escapeKeyDown") {
      setOpen(true);
    } else {
      setOpen(false);
      reloadPage();
    }
  };

  const reloadPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 500);
  };
  return (
    <div>
      <Button
        variant="contained"
        title="Hinzufügen"
        color="success"
        onClick={handleClickOpen}
        size="medium"
        endIcon={<AddBoxIcon fontSize="inherit" />}
      >
        Material erstellen
      </Button>

      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <Grid container spacing={2} columns={2}>
          <Grid item xs={1}>
            <DialogTitle>Material erstellen</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <DialogActions>
              <Button startIcon={<CloseIcon />} onClick={handleClose}>
                Schließen
              </Button>
            </DialogActions>
          </Grid>
        </Grid>

        <DialogContent>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Materialform />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddButton;
