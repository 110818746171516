import React from "react";
import AuftragButton from "../Auftrag/AuftragButton";
import ReservierenButton from "../Reservieren/ReservierenButton";

function ButtonContainer({ data }) {
  switch (data.status) {
    case "lager":
      return <ReservierenButton id={data.id} status={data.status} />;
      break;
    case "reserviert":
      return <AuftragButton data={data} />;
      break;
    default:
      return "";
  }
}

export default ButtonContainer;
