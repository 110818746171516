import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AuftragListe from "./AuftragListe";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CloseIcon from "@mui/icons-material/Close";

function AuftragButton({ data }) {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      setOpen(false);
    } else if (reason == "escapeKeyDown") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <div>
      <IconButton variant="text" title="Aufträge" onClick={handleClick}>
        <LocalOfferIcon />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Typography variant="inherit">
            Artikel: <strong>{data.id}</strong>
          </Typography>
        </DialogTitle>

        <Grid container spacing={2} columns={2}></Grid>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ textDecoration: "underline" }}>
            Aufträge:
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <AuftragListe data={data} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<CloseIcon />} onClick={handleClose}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AuftragButton;
