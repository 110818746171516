import React from "react";

function StaerkeCon(props) {
  if (props.staerke == 0) {
    return <div></div>;
  } else {
    return <div>{props.staerke}</div>;
  }
}

export default StaerkeCon;
