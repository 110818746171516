import React, { useState, useEffect, useReducer, useContext } from "react";
import {
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import ArtikelContext from "../../../context/artikelContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const rolleReducer = (state, event) => {
  if (event.reset) {
    return {
      material: "",
      staerke: "",
      laenge: "",
      breite: "",
    };
  }
  return {
    ...state,
    staerke: null,
    [event.name]: event.value,
  };
};

function RolleForm() {
  const [rolleData, setRolleData] = useReducer(rolleReducer, {});
  const rolletext = "Rolle";

  const { rollematerials, createArtikel } = useContext(ArtikelContext);

  const handleChange = (e) => {
    setRolleData({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      `material: ${rolleData.material}, stärke: ${rolleData.staerke}, länge: ${rolleData.laenge}, breite: ${rolleData.breite}`
    );

    createArtikel(rolleData, rolletext);
    setRolleData({ reset: true });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Material</InputLabel>
                <Select
                  required
                  label="Material"
                  name="material"
                  value={rolleData.material || ""}
                  onChange={handleChange}
                  MenuProps={MenuProps}
                  fullWidth
                >
                  {rollematerials.map((material) => (
                    <MenuItem key={material.id} value={material.material}>
                      {material.material}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Länge</InputLabel>
                <OutlinedInput
                  required
                  name="laenge"
                  type="number"
                  value={rolleData.laenge || ""}
                  onChange={handleChange}
                  inputProps={{
                    inputProps: {
                      min: 0,
                      step: ".01",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">cm</InputAdornment>
                  }
                  label="Länge"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Breite</InputLabel>
                <OutlinedInput
                  required
                  name="breite"
                  type="number"
                  value={rolleData.breite || ""}
                  onChange={handleChange}
                  inputProps={{
                    inputProps: {
                      min: 0,
                      step: ".01",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">cm</InputAdornment>
                  }
                  label="Breite"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button variant="contained" type="submit" fullWidth>
                Hinzufügen
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </form>
    </React.Fragment>
  );
}

export default RolleForm;
