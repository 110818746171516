import React, { useState, useEffect, createContext } from "react";

const NotificationContext = createContext({
  notification: null,
  notificationText: null,
  open: false,
  success: () => {},
  error: () => {},
  clear: () => {},
  close: () => {},
});

const STATES = {
  SUCCESS: "success",
  ERROR: "error",
};

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [notificationText, setNotificationText] = useState(null);
  const [open, setOpen] = useState(false);

  const success = (text) => {
    window.scroll(0, 0);
    setNotificationText(text);
    setNotification(STATES.SUCCESS);
    setOpen(true);
  };

  const error = (text) => {
    window.scroll(0, 0);
    setNotificationText(text);
    setNotification(STATES.ERROR);
    setOpen(true);
  };

  const clear = () => {
    setNotification(null);
    setNotificationText(null);
    setOpen(false);
  };

  const close = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        notificationText,
        open,
        success,
        error,
        clear,
        close,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider };
export default NotificationContext;
