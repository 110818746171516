import React from "react";
import { Box } from "@mui/material";
import MaterialTable from "./Material/MaterialTable";
import Erstellen from "./Material/Erstellen/Erstellen";
import { MaterialContextProvider } from "../context/materialContext";

function Material() {
  return (
    <div>
      <MaterialContextProvider>
        <Box sx={{ mb: 3 }}>
          <Erstellen />
        </Box>
        <MaterialTable />
      </MaterialContextProvider>
    </div>
  );
}

export default Material;
