import React, { useState, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import InfoButton from "./Materialinfo/InfoButton";
import MaterialContext from "../../context/materialContext";
import FormatCell from "./Cell/FormatCell";
import KategorieCell from "./Cell/KategorieCell";

function MaterialTable() {
  const { materials } = useContext(MaterialContext);

  const columns = [
    {
      field: "material",
      headerName: "Material",
      flex: 1,
    },
    {
      field: "staerke",
      headerName: "Stärke",
      width: 70,
      valueGetter: (params) => {
        const staerke = params.row.staerke;

        if (staerke != null) {
          return `${staerke}`;
        } else {
          return "";
        }
      },
    },
    {
      field: "laenge",
      headerName: "Länge",
      width: 100,
      valueGetter: (params) => {
        const laenge = params.row.laenge;

        if (laenge != null) {
          return `${laenge} cm`;
        } else {
          return "";
        }
      },
    },
    {
      field: "breite",
      headerName: "Breite",
      width: 100,
      valueGetter: (params) => {
        const breite = params.row.breite;

        if (breite != null) {
          return `${breite} cm`;
        } else {
          return "";
        }
      },
    },
    {
      field: "format",
      headerName: "Format",
      width: 100,
      renderCell: (params) => <FormatCell format={params.row.format} />,
    },
    {
      field: "kategorie",
      headerName: "Kategorie",
      flex: 1,
      renderCell: (params) => (
        <KategorieCell kategorie={params.row.kategorie} />
      ),
    },
    {
      field: "materialbeschreibung",
      headerName: "Beschreibung",
      flex: 1,
    },
    {
      field: "ek_preis",
      headerName: "Einkaufspreis",
      flex: 1,
      valueGetter: (params) => {
        const ek = params.row.ek_preis;
        if (ek != null) {
          return `${ek} CHF`;
        } else {
          return "";
        }
      },
    },
    {
      field: "vk_preis",
      headerName: "Verkaufspreis",
      flex: 1,
      valueGetter: (params) => {
        const vk = params.row.vk_preis;
        if (vk != null) {
          return `${vk} CHF`;
        } else {
          return "";
        }
      },
    },
    {
      field: "lieferant",
      headerName: "Lieferant",
      flex: 1,
    },
    {
      field: "liefernummer",
      headerName: "Lieferantennummer",
      flex: 1,
    },
    {
      field: "lieferbeschreibung",
      headerName: "Lieferbeschreibung",
      flex: 1,
    },
    {
      field: "info",
      headerName: "",
      flex: 1,
      renderCell: (params) => <InfoButton id={params.row.id} />,
    },
  ];

  return (
    <>
      <div style={{ height: 800 }}>
        <DataGrid
          rows={materials}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: { showQuickFilter: true },
            quickFilterProps: { debounceMs: 500 },
          }}
        />
      </div>
    </>
  );
}

export default MaterialTable;
