import React, { useContext } from "react";
import { Alert, Snackbar } from "@mui/material";

import NotificationContext from "../../context/notificationContext";

function NotificationBar() {
  const notificationContext = useContext(NotificationContext);
  return (
    notificationContext.notification !== null && (
      <Snackbar
        open={notificationContext.open}
        autoHideDuration={5000}
        onClose={notificationContext.close}
      >
        <Alert
          onClose={notificationContext.close}
          severity={notificationContext.notification}
          sx={{ width: "100%" }}
        >
          {notificationContext.notificationText}
        </Alert>
      </Snackbar>
    )
  );
}

export default NotificationBar;
