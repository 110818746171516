import { createTheme } from "@mui/material";
export const theme = createTheme({
  palette: {
    primary: { main: "#6F818C" },
    secondary: { main: "#FFB000" },
    info: {
      main: "#F451A0",
    },
  },
  typography: {
    fontSize: 16,
    button: {
      textTransform: "none",
    },
  },
});
