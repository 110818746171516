import React, { useState } from "react";

import { Box, Tab } from "@mui/material";
import { Container } from "@mui/system";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RolleForm from "./RolleForm";
import PlatteForm from "./PlatteForm";

function AddTabs() {
  const [value, setValue] = useState("rolle");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <React.Fragment>
        {/* <Container component="main" maxWidth="sm" sx={{ mb: 4 }}> */}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="Rolle" value="rolle" />
              <Tab label="Platte" value="platte" />
            </TabList>
          </Box>
          <TabPanel value="rolle">
            <RolleForm />
          </TabPanel>
          <TabPanel value="platte">
            <PlatteForm />
          </TabPanel>
        </TabContext>
        {/* </Container> */}
      </React.Fragment>
    </div>
  );
}

export default AddTabs;
