import React, { useEffect, useState, useContext } from "react";
import { FormControl, Grid, TextField, IconButton } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import ArtikelContext from "../../../context/artikelContext";

export default function ReservierenForm(props) {
  const [auftragData, setAuftragData] = useState({
    id: props.id,
    auftrag: "",
  });
  const status = { id: props.id, status: "reserviert" };

  const { createVerheiraten, updateStatus } = useContext(ArtikelContext);

  const handleChange = (e) => {
    setAuftragData((prevVal) => ({
      ...prevVal,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({
      data: { auftragData },
    });
    createVerheiraten(auftragData);
    updateStatus(status);
    setAuftragData((prev) => ({ ...prev, auftrag: "" }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <Grid container spacing={3}>
            <Grid item xs={10} sm={10}>
              <TextField
                id="auftrag"
                name="auftrag"
                label="Auftrag"
                type="number"
                value={auftragData.auftrag || ""}
                onChange={handleChange}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={2} sm={2}>
              <IconButton
                variant="text"
                title="hinzufügen"
                color="success"
                sx={{ fontSize: 20 }}
                type="submit"
              >
                <AddCircle />
              </IconButton>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </div>
  );
}
