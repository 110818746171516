import React from "react";
import { Box, Stack, Typography } from "@mui/material";

function Reservieren({ auftraege }) {
  return (
    <div>
      {auftraege.map((auftrag, index) => (
        <div key={index}>
          <Box>
            <Stack>
              <Typography>- {auftrag.data.auftragData.auftrag}</Typography>
            </Stack>
          </Box>
        </div>
      ))}
    </div>
  );
}

export default Reservieren;
