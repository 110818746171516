import React from "react";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import StyleIcon from "@mui/icons-material/Style";

function StatusIcon(props) {
  switch (props.status) {
    case "lager":
      return <WarehouseIcon />;

    case "reserviert":
      return <StyleIcon />;

    case "verwendet":
      return <DoDisturbIcon />;

    default:
      return "";
  }
}

export default StatusIcon;
