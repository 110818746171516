import React, { useEffect, useState, useContext } from "react";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteModal from "./DeleteModal";

function AuslagernButton({ id }) {
  const [modalState, setModalState] = useState(false);
  const [artikel, setArtikel] = useState({ id: "" });

  const handleClick = (e) => {
    e.preventDefault();
    setModalState(true);
  };

  const openModal = () => {
    setModalState(!modalState);
  };

  useEffect(() => {
    setArtikel((prevArtikel) => ({ ...prevArtikel, id: id }));
  }, []);

  return (
    <div>
      <div>
        <IconButton
          variant="text"
          title="Auslagern"
          color="error"
          onClick={handleClick}
        >
          <CancelIcon />
        </IconButton>
      </div>
      {modalState && (
        <DeleteModal action={openModal} artikel={artikel} toggle={modalState} />
      )}
    </div>
  );
}

export default AuslagernButton;
