import React, { useState, useEffect, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MaterialBadge from "./Cell/MaterialBadge";
import StaerkeCon from "./Cell/StaerkeCon";
import CellTools from "./Cell/CellTools";
import StatusIcon from "./Cell/StatusIcon";
import ArtikelContext from "../../context/artikelContext";

function ArtikelTable() {
  const [pageSize, setPageSize] = useState(100);
  const [status, setStatus] = useState({
    id: "",
    status: "",
  });

  const { artikels, updateStatus } = useContext(ArtikelContext);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 90,
      flex: 1,
      sortable: false,
    },
    {
      field: "artikel",
      headerName: "Artikel",
      minWidth: 90,
      flex: 1,
      sortable: true,
      valueGetter: (params) => {
        const staerke = params.row.staerke;
        const material = params.row.shortcut;

        if (staerke !== null) {
          return `${material}-${staerke}`;
        } else {
          return `${material}`;
        }
      },
    },
    {
      field: "material",
      headerName: "Material",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <MaterialBadge
          kategorie={params.row.kategorie}
          material={params.row.material}
        />
      ),
    },
    {
      field: "staerke",
      headerName: "Stärke",
      width: 70,
      renderCell: (params) => <StaerkeCon staerke={params.row.staerke} />,
    },
    {
      field: "laenge",
      headerName: "Länge",
      minWidth: 160,
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        return `${params.row.laenge.replace(".", ",")} cm`;
      },
    },
    {
      field: "breite",
      headerName: "Breite",
      minWidth: 160,
      flex: 1,
      sortable: false,
      valueGetter: (params) => {
        return `${params.row.breite.replace(".", ",")} cm`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 20,
      flex: 1,
      type: "singleSelect",
      valueOptions: ["lager", "verwendet"],
      editable: true,
      sortable: true,
      renderCell: (params) => <StatusIcon status={params.row.status} />,
    },
    {
      field: "auslagern",
      headerName: "",
      minWidth: 20,
      flex: 1,
      sortable: false,
      renderCell: (params) => <CellTools data={params.row} />,
    },
  ];

  const handleCommit = (params, e) => {
    switch (params.field) {
      case "status":
        artikels.map((a) => {
          if (a.id === params.id) {
            setStatus((values) => ({
              ...values,
              id: a.id,
              status: e.target.value,
            }));
          }
        });
        break;
    }
    console.log("Para:", params, "Event", e);
  };

  useEffect(() => {
    updateStatus(status);
  }, [status]);

  return (
    <div style={{ height: 800 }}>
      <DataGrid
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 15, 100]}
        columns={columns}
        rows={artikels}
        editMode="cell"
        onCellEditCommit={handleCommit}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </div>
  );
}

export default ArtikelTable;
