import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  IconButton,
  ListItem,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  List,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { useReactToPrint } from "react-to-print";
import { Box } from "@mui/system";
import QRCode from "react-qr-code";

function PrintModal({ action, data }, toggle) {
  const componentRef = useRef();
  const auftraege = data.auftraege?.split(",");

  const handleHide = () => {
    action();
  };

  const handleClose = () => {
    action();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      <Dialog open={toggle} onClose={handleClose} disableEscapeKeyDown>
        <Grid container spacing={2} columns={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <DialogActions>
              <IconButton onClick={handlePrint}>
                <PrintIcon />
              </IconButton>
            </DialogActions>
          </Grid>
        </Grid>
        <DialogContent>
          <Box
            sx={{
              margin: 5,
              border: "black solid 2px",
              padding: 1,
            }}
            ref={componentRef}
          >
            <Grid container>
              <Grid item xs={4} sm={4}>
                <QRCode value={data.id} size={100} />
              </Grid>
              <Grid item xs={8} sm={8}>
                <List>
                  <ListItem>
                    <h1>
                      <strong>{data.id}</strong>
                    </h1>
                  </ListItem>
                  <ListItem>
                    <h6>
                      {data.material} | {data.laenge} cm x {data.breite} cm
                    </h6>
                  </ListItem>
                  {auftraege?.map((a) => {
                    return <ListItem>{a}</ListItem>;
                  })}
                </List>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default PrintModal;
