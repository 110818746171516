import React, { useState, useContext, useAuth } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Appbar from "./Appbar/Appbar";
import Artikel from "./Lager/Artikel";
import Material from "./Lager/Material";

function Home() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Appbar />
      <Box sx={{ borderBottom: "none", borderColor: "divider", m: 3 }}>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="overview tabs">
            <Tab label="Artikel" value="1" />
            <Tab label="Material" value="2" />
          </TabList>
          <TabPanel value="1">
            <Artikel />
          </TabPanel>
          <TabPanel value="2">
            <Material />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}

export default Home;
