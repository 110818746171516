import React, { useContext, useEffect } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import MaterialContext from "../../../context/materialContext";

function MaterialInfo(props) {
  const { materials } = useContext(MaterialContext);
  const material = materials.filter((material) => material.id == props.id);

  return (
    <>
      {material.map((m) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ m: 4, p: 4 }}>
              <Typography variant="h6">Materialinformationen</Typography>
              <Typography>
                <strong>Material:</strong> {m.material}
              </Typography>
              <Typography>
                <strong>Format:</strong> {m.format}
              </Typography>
              <Typography>
                <strong>Kategorie:</strong> {m.kategorie}
              </Typography>
              <Typography>
                <strong>Länge:</strong> {m.laenge}
              </Typography>
              <Typography>
                <strong>Breite:</strong> {m.breite}
              </Typography>
              <Typography>
                <strong>Stärke: </strong>
                {m.staerke}
              </Typography>
              <Typography>
                <strong>Beschreibung:</strong> {m.materialbeschreibung}
              </Typography>
              <Typography>
                <strong>Lieferant:</strong> {m.lieferant}
              </Typography>
              <Typography>
                <strong>Liefernummer: </strong>
                {m.liefernummer}
              </Typography>
              <Typography>
                <strong>Beschreibung:</strong> {m.lieferbeschreibung}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default MaterialInfo;
