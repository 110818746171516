import "./App.css";
import React, { useState, createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import NotificationBar from "./components/Notification/NotificationBar";

function App() {
  return (
    <div className="App">
      <NotificationBar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/Login" element={<Home />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
