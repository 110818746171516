import React, { useEffect, useState, useContext } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  IconButton,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CancelIcon from "@mui/icons-material/Cancel";
import NotificationContext from "../../../context/notificationContext";

function AuftragListe({ data }) {
  const [auftraege, setAuftraege] = useState([]);

  // const notiContext = useContext(NotificationContext);

  // const handleClick = (e, auftrag) => {
  //   console.log("DELETE Auftrag", auftrag);
  //   e.preventDefault();
  //   deleteAuftrag(auftrag);
  // };

  // const deleteAuftrag = (auftrag) => {
  //   fetch("http://localhost/API/Lager/deleteAuftrag.php", {
  //     method: "DELETE",
  //     body: JSON.stringify({ auftrag: auftrag }),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         notiContext.error("Auftrag nicht gelöscht!");
  //         throw new Error("Auftrag nicht gelöscht!");
  //       }
  //       notiContext.success("Auftrag gelöscht!");
  //       response.text();
  //     })
  //     .then((data) => {
  //       setAuftraege(data);
  //     })
  //     .catch((error) => {
  //       console.error("Fehler beim Löschen!", error);
  //     });
  // };

  const setData = () => {
    setAuftraege(data.auftraege?.split(","));
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <>
      <List disablePadding>
        {auftraege.map((auftrag, i) => {
          return (
            <ListItem key={i}>
              <ListItemIcon>
                <CircleIcon sx={{ fontSize: "10px", color: "black" }} />
              </ListItemIcon>
              <ListItemText>{auftrag}</ListItemText>
              {/* <ListItemButton onClick={(e) => handleClick(e, auftrag)}>
                <CancelIcon sx={{ fontSize: "20px" }} />
              </ListItemButton> */}
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default AuftragListe;
