import { Container } from "@mui/system";
import React, { useState } from "react";
import Reservieren from "./Reservieren";
import ReservierenForm from "./ReservierenForm";

function ReservierenListe(props) {
  const [auftraege, setAuftrage] = useState([]);

  const addAuftrag = (auftrag) => {
    const newAuftraege = [auftrag, ...auftraege];
    setAuftrage(newAuftraege);
  };

  return (
    <>
      <Container>
        <ReservierenForm onSubmit={addAuftrag} id={props.id} />
        <Reservieren auftraege={auftraege} />
      </Container>
    </>
  );
}

export default ReservierenListe;
