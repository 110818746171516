import React, { useState, useReducer, useEffect, useContext } from "react";
import {
  FormGroup,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  Grid,
  InputLabel,
} from "@mui/material";
import MaterialContext from "../../../context/materialContext";

const format = [
  { id: 1, format: "Rolle" },
  { id: 2, format: "Platte" },
];
const kategorie = [
  { id: 1, kategorie: "Blache" },
  { id: 2, kategorie: "Tafel" },
  { id: 3, kategorie: "Aufkleber" },
  { id: 4, kategorie: "Magnet" },
  { id: 5, kategorie: "Plakat" },
];

const materialReducer = (state, event) => {
  if (event.reset) {
    return {
      material: "",
      staerke: "",
      format: "",
      kategorie: "",
      shortcut: "",
    };
  }
  return {
    ...state,
    [event.name]: event.value,
  };
};

function Materialform() {
  const [materialData, setMaterialData] = useReducer(materialReducer, {
    material: "",
    staerke: "",
    format: "",
    kategorie: "",
    shortcut: "",
  });
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");

  const { createMaterial } = useContext(MaterialContext);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChange = (e) => {
    setMaterialData({
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(
      `material: ${materialData.material}, stärke: ${materialData.staerke}, format: ${materialData.format}, kategorie: ${materialData.kategorie}, shortcut: ${materialData.shortcut}`
    );
    createMaterial(materialData);
    setMaterialData({ reset: true });
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                name="material"
                value={materialData.material || ""}
                onChange={handleChange}
                type="text"
                label="Material"
                variant="outlined"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                name="staerke"
                value={materialData.staerke || ""}
                onChange={handleChange}
                inputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                type="number"
                label="Stärke"
                variant="outlined"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="shortcut"
                value={materialData.shortcut || ""}
                onChange={handleChange}
                type="text"
                label="Kürzel"
                variant="outlined"
                fullWidth
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Format</InputLabel>
                <Select
                  name="format"
                  value={materialData.format || ""}
                  label="Format"
                  fullWidth
                  onChange={handleChange}
                >
                  {format.map((f) => (
                    <MenuItem key={f.id} value={f.format}>
                      {f.format}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel>Kategorie</InputLabel>
                <Select
                  name="kategorie"
                  value={materialData.kategorie || ""}
                  label="Kategorie"
                  fullWidth
                  onChange={handleChange}
                >
                  {kategorie.map((k) => (
                    <MenuItem key={k.id} value={k.kategorie}>
                      {k.kategorie}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button variant="contained" type="submit" fullWidth>
                Hinzufügen
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </form>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {status === "success" ? (
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Material hinzugefügt
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Material konnte nicht eingelagert werden!
          </Alert>
        )}
      </Snackbar>
    </React.Fragment>
  );
}

export default Materialform;
