import React, { useEffect, useState, useContext } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ArtikelContext from "../../../context/artikelContext";

function DeleteModal({ action, artikel }, toggle) {
  const { deleteArtikel } = useContext(ArtikelContext);
  const handleSubmit = () => {
    console.log(artikel);
    deleteArtikel(artikel);
    action();
    reloadPage();
  };
  const handleHide = () => {
    action();
  };
  const handleClick = () => {
    action();
  };

  const reloadPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 500);
  };

  return (
    <React.Fragment>
      <Modal
        show={toggle}
        onHide={handleHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Auslagern</Modal.Title>
        </Modal.Header>
        <Modal.Body>Artikel wirklich auslagern?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClick}>
            Nein
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Ja
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default DeleteModal;
