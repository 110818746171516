import React from "react";
import { Grid } from "@mui/material";
import AuslagernButton from "../Auslagern/AuslagernButton";
import PrintButton from "../Print/PrintButton";
import ButtonContainer from "./ButtonContainer";

function CellTools({ data }) {
  return (
    <div>
      <Grid container>
        <Grid item>
          <ButtonContainer data={data} />
        </Grid>
        <Grid item>
          <PrintButton data={data} />
        </Grid>
        <Grid item>
          <AuslagernButton id={data.id} />
        </Grid>
      </Grid>
    </div>
  );
}

export default CellTools;
