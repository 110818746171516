import React from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import RectangleOutlinedIcon from "@mui/icons-material/RectangleOutlined";
import platte from "../../../_img/platte.png";
import rolle from "../../../_img/rolle.png";

function FormatCell(props) {
  switch (props.format) {
    case "Rolle":
      return <img src={rolle} alt="platte" width="25" />;

    case "Platte":
      return <img src={platte} alt="platte" width="25" />;

    default:
      return "";
  }
}

export default FormatCell;
