import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MaterialInfo from "./MaterialInfo";

function InfoButton(props) {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      setOpen(false);
    } else if (reason == "escapeKeyDown") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  return (
    <div>
      <IconButton onClick={handleClick}>
        <InfoOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <Grid container spacing={2} columns={2}>
          <Grid item xs={1}>
            <DialogTitle></DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <DialogActions>
              <Button startIcon={<CloseIcon />} onClick={handleClose}>
                Schließen
              </Button>
            </DialogActions>
          </Grid>
        </Grid>

        <DialogContent>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <MaterialInfo id={props.id} />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InfoButton;
