import React, { useState, useEffect, useContext } from "react";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import PrintModal from "./PrintModal";

function PrintButton({ data }) {
  const [modalState, setModalState] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setModalState(true);
  };

  const openModal = () => {
    setModalState(!modalState);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <PrintIcon />
      </IconButton>
      {modalState && (
        <PrintModal action={openModal} toggle={modalState} data={data} />
      )}
    </div>
  );
}

export default PrintButton;
