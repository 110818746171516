import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import ReservierenListe from "./ReservierenListe";

function ReservierenButton(props) {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      setOpen(false);
    } else if (reason == "escapeKeyDown") {
      setOpen(true);
    } else {
      setOpen(false);
      reloadPage();
    }
  };

  const reloadPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <IconButton variant="text" title="Reservieren" onClick={handleClick}>
        <ArrowForwardIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <Grid container spacing={2} columns={2}>
          <Grid item xs={1}>
            <DialogTitle>Aufträge hinzufügen</DialogTitle>
          </Grid>
          <Grid item xs={1}>
            <DialogActions>
              <Button startIcon={<CloseIcon />} onClick={handleClose}>
                Schließen
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <DialogContent>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <ReservierenListe id={props.id} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ReservierenButton;
