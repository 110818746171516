import React, { createContext, useState, useEffect, useContext } from "react";
import NotificationContext from "./notificationContext";

const ArtikelContext = createContext();

const ArtikelContextProvider = ({ children }) => {
  const [artikels, setArtikels] = useState([]);
  const [plattematerials, setPlattematerials] = useState([]);
  const [rollematerials, setRollematerials] = useState([]);

  const notiContext = useContext(NotificationContext);

  const fetchArtikel = async () => {
    fetch("./API/Lager/getArtikel.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setArtikels(data);
      })
      .catch((error) => console.log("An error occured", error));
  };

  const fetchPlattematerials = async () => {
    fetch("./API/Lager/getPlattenmaterial.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setPlattematerials(data);
      });
  };

  const fetchRollematerials = async () => {
    fetch("./API/Lager/getRollenmaterial.php")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRollematerials(data);
      });
  };

  const createArtikel = (data, format) => {
    fetch("./API/Lager/createArtikel.php", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          notiContext.error(`${format} konnte nicht eingelagert werden`);
          throw new Error(`${format} konnte nicht eingelagert werden`);
        }
        notiContext.success(`${format} eingelagert!`);

        return response.json();
      })
      .catch((error) => {
        console.error(`Fehler beim Erstellen einer ${format}!`, error);
      });
  };

  const createVerheiraten = async (data) => {
    fetch("./API/Lager/createArtikelAuftrag.php", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          notiContext.error("Konnte nicht verheiratet werden");
          throw new Error("Konnte nicht verheiratet werden");
        }
        notiContext.success("Verheiratet!");
        return response.json();
      })
      .catch((error) => {
        console.error("Fehler beim Verheiraten!", error);
      });
  };

  const updateStatus = async (status) => {
    fetch("./API/Lager/updateStatus.php", {
      method: "POST",
      body: JSON.stringify(status),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Status nicht aktualisiert");
        }
        return response.json();
      })
      .catch((error) => {
        console.error("Fehler beim Aktualisieren!", error);
      });
  };

  const deleteArtikel = (artikel) => {
    fetch("./API/Lager/deleteArtikel.php", {
      method: "DELETE",
      body: JSON.stringify(artikel),
    })
      .then((response) => {
        if (!response.ok) {
          notiContext.error("Artikel nicht ausgelagert!");
          throw new Error("Artikel nicht ausgelagert!");
        }
        notiContext.success("Artikel ausgelagert!");
        response.text();
      })
      .catch((error) => {
        console.error("Fehler beim Auslagern!", error);
      });
  };

  useEffect(() => {
    fetchArtikel();
    fetchPlattematerials();
    fetchRollematerials();
  }, []);

  return (
    <ArtikelContext.Provider
      value={{
        artikels,
        plattematerials,
        rollematerials,
        setArtikels,
        createArtikel,
        createVerheiraten,
        updateStatus,
        deleteArtikel,
      }}
    >
      {children}
    </ArtikelContext.Provider>
  );
};

export { ArtikelContextProvider };
export default ArtikelContext;
